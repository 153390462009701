<template>
  <div :class="['project-card', firstStudyId == null ? 'project-card-disabled' : '', isDropdownOpen ? 'unset-overflow' : '']">
    <div class="position-relative">
      <b-badge variant="secondary" class="badge-container" v-if="this.project.is_demo_project">Demo Project</b-badge>
      <b-badge variant="secondary" class="badge-container" v-if="this.projectFromAnotherCompany">Shared by: {{projectCompanyName}}</b-badge>
      <figure v-if="!showProjectDetails">
        <img :src="this.project.image" alt="">
      </figure>
    </div>
    <div class="title-container">
      <div class="d-flex flex-row no-top-margin-all-descendants">
        <h2 :class="[firstStudyId == null ? 'disabled' : 'enabled']">
          <router-link class="text-break" v-if="!showProjectDetails" :to="route">{{ project.name }}</router-link>
          <div v-else>{{ project.name }} </div>
        </h2>

        <b-dropdown v-if="projectShareId" class="menu-dropdown-button dropdown-item-custom ml-2" variant="outline-primary btn-sm-custom bg-white border-white text-dark" no-caret>
          <template  #button-content>
            <b-icon icon='three-dots-vertical' scale="0.75" class="rounded-circle bg-light three-dots"></b-icon>
          </template>
          <b-dropdown-item-button class="is-open" @click="toggleModal">Remove project</b-dropdown-item-button>
          <!-- TODO: Remove if not needed in the future -->
          <!-- <div class="dropdown-divider"></div> -->
          <!-- <div class="dropdown-item">Delete project</div> -->
        </b-dropdown>
      </div>
      <!-- Modal -->
      <b-modal centered v-model="isModalOpen">
        <template #modal-header>
          <h4>Remove Project</h4>
        </template>

        <template #default>
          <p>Are you sure you want to revoke your access to this project?</p>
        </template>

        <template #modal-footer>
          <b-button @click="isModalOpen = false" class='float-left remove-project-btn'>No</b-button>
          <b-button @click="removeProject" variant='danger' class='float-right remove-project-btn'>Yes</b-button>
        </template>
      </b-modal>
      <b-container class="address-container">
        <b-row>
          <b-col>
            <p>{{ this.streetName }}<br>
            {{ this.addressPostalCode }}<br>
            {{ this.latLongString }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="project-metadata-container">
      <b-container v-if="showProjectDetails" class="p-0 project-detail-table-container">
        <b-table-simple 
          borderless 
          class='project-detail-table pr-4' 
          v-for="(study, index) in sortedLabels" 
          :key="study.study.name">
          <h5>{{ study.study.name }}</h5>
          <b-thead>
            <b-tr>
              <b-th class="pb-0">
                <b-dropdown id="dropdown-1" :text="sortByPlaceholder" size="sm" variant="outline-secondary bg-white" toggle-class="sort-by-button">
                  <b-dropdown-item-button class="dropdown-button" @click="setSortingVariable('created_at', index)">{{sortByNameMapper['created_at']}}</b-dropdown-item-button>
                  <b-dropdown-item-button class="dropdown-button" @click="setSortingVariable('label', index)">{{sortByNameMapper['label']}}</b-dropdown-item-button>
                  <b-dropdown-item-button class="dropdown-button" @click="setSortingVariable('simulation_type', index)">{{sortByNameMapper['simulation_type']}}</b-dropdown-item-button>
                  <b-dropdown-item-button class="dropdown-button" @click="setSortingVariable('category', index)">{{sortByNameMapper['category']}}</b-dropdown-item-button>
                </b-dropdown>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th class="scenario-name-column">Scenario</b-th>
              <b-th class="scenario-status-column" >Status</b-th>
              <b-th class="scenario-status-column" >Type</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(scenario, index) in study.configs" :key="index">
              <b-th class='scenario-name' @click='viewScenario(study.study, scenario.configurationId)'>
                {{ scenario.label }}
              </b-th>
              <b-td v-if="scenario.category === 'RESULT'">
                <b-badge class="scenario-status-badge" pill variant='success'>Complete</b-badge>
              </b-td>
              <b-td v-else-if="scenario.category === 'INBOUND'">
                <b-badge class="scenario-status-badge" pill variant='secondary'>Not Submitted</b-badge>
              </b-td>
              <b-td v-else-if="scenario.category === 'SUBMITTED'">
                <b-badge class="scenario-status-badge" pill variant='primary'>In Progress</b-badge>
              </b-td>
              <b-td class="text-right">
                <b-badge class="mr-1 pb-0">{{scenario.simulation_type === "ML" ? "AI" : scenario.simulation_type}}</b-badge>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-container>
      <b-container v-else class="p-0">
        <b-row no-gutters class="no-top-margin-all-descendants">
          <b-col>
            <div class="d-flex">
              <h4>Project ID: <span>{{ project.id }}</span></h4>
            </div>
          </b-col>
          <b-col >
            <div class="d-flex" :style="{visibility: showClientId ? 'visible' : 'hidden'}">
              <h4>Client ID: <span>{{ projectClientId }}</span></h4>
            </div>
          </b-col>
          <b-col>
            <b-button v-if="canViewProjectDetails" variant='link' size="sm" class="pl-0" @click='openProjectSettings'>
              <b-icon icon='gear'></b-icon>
              Properties
            </b-button>
            <b-button v-else-if="canReshareProject" variant='link' size="sm" class="pl-0" @click='toggleShareModal'>
              <b-icon icon='share'></b-icon>
              Share
            </b-button>
            <b-modal title="Share Project" :hide-footer=true v-model="shareModalIsVisible">
              <template #default>
                <b-input-group prepend="Email">
                  <b-form-input v-model="selectedUserEmail"></b-form-input>
                  <b-input-group-append class="my-0 ml-2">
                    <b-button variant="primary" :disabled="isLoading || !selectedUserEmail" @click="addUser(selectedUserEmail)">
                      <b-spinner v-if='isLoading' small></b-spinner>
                      {{isLoading ? 'Adding' : 'Add'}}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-modal>
          </b-col>
        </b-row>
        <hr class="sep-line">
      </b-container>
      <b-container class="p-0">
        <b-row no-gutters class="no-top-margin-all-descendants">
          <b-col >
            <div class='d-flex flex-column'>
             <h4 class="d-flex flex-row"><span class="mr-1 mt-0">{{ scenarioCount }}</span> scenarios</h4>
             <h4 class="d-flex flex-row"><span class="mr-1 mt-0">{{ completedScenarioCount }}</span> complete</h4>
            </div>
          </b-col>
          <b-col v-if="!canAddScenario || !isProjectInCompany"></b-col> <!-- an empty column will push the "details" button to the right for read-only users (who don't see the "Add Scenario" button)-->
          <b-col>
            <b-button v-if='!showProjectDetails' variant='link' size="sm" class="pl-0" @click='toggleProjectDetails'>
              <b-icon v-if='!projectHasErrors || !canAddScenario' icon='list-task'></b-icon>
              Details
            </b-button>
            <b-button v-else variant='link' size="sm" class="pl-0" @click='toggleProjectDetails'>
              <b-icon icon='layout-text-window-reverse'></b-icon>
              Overview
            </b-button>
          </b-col>
          <b-col v-if="canAddScenario && isProjectInCompany">
            <b-button size="sm" variant="outline-primary" @click="$emit('add-scenario')">Add Scenario</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADD_SCENARIO,
  CHANGE_PROJECT,
  EDIT_ALL_COMPANY_PROJECTS
} from '@/constants/permissions';
import {
  UPDATE_USER_PROJECT_SHARE
} from '@/store/actions/permissions';

export default {
  name: 'ProjectCard',
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showProjectDetails: false,
      shareModalIsVisible: false,
      isLoading: false,
      selectedUserEmail: null,
      isDropdownOpen: false,
      isModalOpen: false,
      sortBy: null,
      sortedLabels: null,
      sortByPlaceholder: 'Sort By',
      sortByNameMapper: {
        'created_at': 'Created Date',
        'label': 'Scenario Name',
        'simulation_type': 'Simulation Type',
        'category': 'Status'
      }
    };
  },
  created() {
    this.sortBy = 'created_at';
    this.sortedLabels = this.simulationLabels;
    for(let i = 0; i < this.simulationLabels.length; i++) {
      this.sortSimulationLabels(this.sortBy, i);
    }
  },
  computed: {
    projectShareId() {
      return this.userProjectShares?.find(userProjectShare => userProjectShare.project.id === this.project.id)?.id;
    },
    projectFromAnotherCompany() {
      return !this.project?.is_demo_project && this.project?.company_id != this.loggedInUser?.company_id;
    },
    projectCompanyName() {
      return this.project?.company?.name;
    },
    streetName() {
      return this.project.address_1;
    },
    addressPostalCode() {
      return this.project.address_2;
    },
    latLongString() {
      if (this.project.latitude !== null && this.project.longitude !== null
        && this.project.latitude !== undefined && this.project.longitude !== undefined) {
        return `${this.project.latitude}\u00B0,${this.project.longitude}\u00B0`;
      } else {
        return null;
      }
    },

    firstStudyId() {
      if (this.project?.studies && this.project.studies.length > 0) {
        return this.project.studies[0].id;
      } else {
        return null;
      }
    },
    firstAlphabeticalConfigurationId() {
      return this.simulationLabels?.[0]?.configs?.slice()
        .sort((first, second) => first.label.localeCompare(second.label))[0]
        ?.configurationId;
    },

    route() {
      if (this.firstStudyId == null || this.firstAlphabeticalConfigurationId == null) {
        return {};
      }
      return {
        name: 'ViewerContainer',
        params: {
          id: this.project.id,
          study: this.firstStudyId,
          configuration: this.firstAlphabeticalConfigurationId,
          tabName: 'Viewer'
        }
      };
    },
    canAddScenario() {
      return (this.$store.getters['allowedPermissions'] || {})[ADD_SCENARIO];
    },
    canChangeProject() {
      return (this.$store.getters['allowedPermissions'] || {})[CHANGE_PROJECT];
    },
    canEditAllProjects() {
      return (this.$store.getters['allowedPermissions'] || {})[EDIT_ALL_COMPANY_PROJECTS];
    },
    isProjectAdmin() {
      if (this.project.project_administrators.some(x => x.user?.id == this.loggedInUser?.id))
        return true;

      return false;
    },
    canViewProjectDetails() {
      if (this.userCompany.is_rwdi)
        return true;
      else
        return ((this.canChangeProject && this.isProjectAdmin) || this.canEditAllProjects) && (this.isProjectInCompany);
    },
    canReshareProject() {
      return !this.project.is_demo_project && this.project.can_reshare;
    },
    projectClientId() {
      return this.project.client_id ?? '- -';
    },
    showClientId() {
      return !!this.project.client_id;
    },
    simulationLabels() {
      let simulationLabels = [];
      let iterationsControl = 1;
      if (this.userCompany?.is_rwdi) {
        iterationsControl = this.project.studies.length;
      }
      for(let i = 0; i < iterationsControl; i++) {
        simulationLabels.push({ study: this.project.studies[i] });

        if(this.loggedInUser?.is_superuser || this.project?.is_demo_project || this.userCompany?.is_rwdi) {
          simulationLabels[i].configs = this.project.studies[i]?.simulation_labels;
        } else if (!this.project?.studies) {
          simulationLabels[i].configs = [];
        } else if(!this.isProjectInCompany) {
          simulationLabels[i].configs = this.project.studies[i]?.simulation_labels.filter(sim => (sim.category === 'RESULT'));
        } else if(this.canAddScenario) {
          // 1.	a user has submitter role and subscription assigned: they can see all scenarios (INBOUNDS and RESULTS, MONTHLY and ONE-TIME)
          if (this.loggedInUser?.subscription) {
            simulationLabels[i].configs = this.project.studies[i]?.simulation_labels;
          } else { // 2.	a user has submitter access but no subscription: they can see all completed scenarios and only INBOUNDS ones if they are ONE-TIME
            simulationLabels[i].configs = this.project.studies[i]?.simulation_labels.filter(sim => (sim.category === 'RESULT')).concat(this.project.studies[i]?.simulation_labels.filter(sim => (sim.category !== 'RESULT' && sim.subscription_type === 'ONE-TIME')));
          }
        } else { // 3.	a user has Read-Only access: they can see only RESULT scenarios
          simulationLabels[i].configs = this.project.studies[i]?.simulation_labels.filter(sim => (sim.category === 'RESULT'));
        }
      }
      // only return simulation labels for studies that have 1 or more configs
      return simulationLabels.filter(study => study.configs.length);
    },
    scenarioCount() {
      return this.simulationLabels?.flatMap(study => study.configs).length || 0;
    },
    completedScenarioCount() {
      return this.simulationLabels?.flatMap(study => study.configs).filter(sim => sim.category === 'RESULT').length || 0;
    },
    projectHasErrors() {
      if(this.project.errors) {
        return this.project.errors.length > 0;
      } else {
        return false;
      }
    },
    isProjectInCompany() {
      return this.loggedInUser?.is_superuser || this.project?.company_id == this.loggedInUser?.company_id || this.userCompany?.is_rwdi;
    },
    ...mapGetters(['loggedInUser', 'userProjectShares', 'userCompany']),
    ...mapGetters('project', ['projectShares'])
  },
  methods: {
    sortSimulationLabels(sortBy, studyIndex) {
      if (this.simulationLabels) {
        if (sortBy == 'created_at') {
          this.sortedLabels[studyIndex].configs = this.simulationLabels[studyIndex].configs.sort(function(a,b) {return new Date(b[sortBy]) - new Date(a[sortBy]);});
        } else {
          this.sortedLabels[studyIndex].configs = this.simulationLabels[studyIndex].configs.sort(function(a,b) {return a[sortBy].toUpperCase() < b[sortBy].toUpperCase() ? -1 : 1;});
        }
      }
    },
    async removeProject() {

      await this.$store.dispatch(UPDATE_USER_PROJECT_SHARE, {
        projectShareId: this.projectShareId,
        share_status: 'REJECTED'
      });

      this.isModalOpen = false;
      this.$store.dispatch('project/reset');
      await this.$store.dispatch('project/getPage');
    },
    toggleProjectDetails() {
      this.showProjectDetails = !this.showProjectDetails;
    },
    toggleShareModal() {
      this.shareModalIsVisible = !this.shareModalIsVisible;
    },
    viewScenario(study, scenarioId) {
      this.$router.push({
        name: 'ViewerContainer',
        params: {
          id: this.project.id,
          study: study.id,
          configuration: scenarioId,
          tabName: 'Viewer'
        }
      });
    },
    setSortingVariable(sortBy, index) {
      this.sortBy = sortBy;
      this.sortByPlaceholder = this.sortByNameMapper[sortBy];
      this.sortSimulationLabels(sortBy, index);
    },
    openProjectSettings() {
      this.$store.dispatch('project/setViewerMode', 'Settings');
      if (this.firstAlphabeticalConfigurationId) {
        this.$router.push({
          name: 'ViewerContainer',
          params: {
            id: this.project.id,
            study: this.firstStudyId,
            configuration: this.firstAlphabeticalConfigurationId,
            tabName: 'properties'
          },
          query: {
            tab: this.project.show_wheel ? 'navigator' : 'details'
          }
        });
      } else {
        this.$router.push({
          name: 'properties',
          params: {
            id: this.project.id,
            tabName: 'properties'
          },
          query: {
            tab: this.project.show_wheel ? 'navigator' : 'details'
          }
        });
      }
    },
    async addUser(selectedUserEmail) {
      if (selectedUserEmail == null) {
        return;
      }
      this.isLoading = true;
      const payload = {
        projectId: this.project.id,
        values: {
          email: selectedUserEmail
        }
      };
      await this.$store.dispatch('project/createUserInvite', payload);
      this.isLoading = false;
      this.shareModalIsVisible = false;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
      this.toggleDropdown();
    }
  }
};
</script>

<style scoped>
.remove-project-btn {
  width: 3.125rem;
  display: inline-block;
}

.menu-dropdown-button /deep/ .dropdown-toggle {
  padding: 0 !important;
}

.dropdown-button {
  width: calc(100% - 3rem);
}

.is-open {
  display: inline-block;
  top: -180%;
  left: 125%;
  padding: 0.2rem 0;
  font-size: 0.8em;
  font-weight: 400;
  max-width: 70%;
  color: var(--grey-700);
}

.dropdown-item-custom /deep/ .dropdown-menu {
  padding: 0 !important;
}

.col-auto {
  max-width: 80%;
}

.dropdown-divider {
  width: 90%;
  margin: 0 auto;
}

.unset-overflow {
  overflow: unset !important;
}

.isOpen {
  font-size: 0.8em;
  font-weight: 400;
  color: var(--grey-700);
}

.badge-container {
  position: absolute;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  z-index: 2;
}
.scenario-status-badge {
  min-width: 5.625rem;
}

.scenario-name-column {
  width: 40%;
}

.scenario-status-column {
  padding-left: 0.75rem !important;
}

.project-card {
  background-color: var(--white);
  box-shadow: 0rem 0.188rem 0.313rem var(--grey-400);
  border-radius: 0.313rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 200ms ease-in-out;
  min-height: 12.5rem;
  width: 100%;
  margin: .75em;
  overflow: hidden;
  position: relative;
}

.project-card h2 a::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.title-container {
  margin-top: 1.5rem;
  padding: 0 1.5em;
}

.project-metadata-container {
  padding: 0 1.5em 1.5em;
}

.project-metadata-container th {
  padding-left: 0;
}

.project-card img {
  align-self: flex-start;
  object-fit: cover;
  height: 100%;
  object-position: 50% 0;
}

.scenarios-info h4 {
  margin-top: 0;
}

.scenarios-info h4 span {
  display: inline;
}

.project-detail-table {
  display: block;
  max-height: 15.313rem;
  width: 91%;
  scroll-behavior: auto;
  font-size: 0.85em !important;
}

.project-detail-table-container {
  height: 15.313rem; 
  overflow-y: scroll;
}

.project-detail-table .scenario-name{
  text-decoration: underline;
  color: #007bff;
}

/* For 2- and 3-column layout */

@media screen and (min-width: 32em) {
  .project-card {
    width: calc(50% - 1.5em);
  }
}

@media screen and (min-width: 48em) {
  .project-card {
    width: calc(33% - 1.5em);
  }
}

.project-card:hover {
  cursor: pointer;
  box-shadow: 0rem 0.313rem 0.313rem var(--grey-500);
}

.project-card-disabled:hover {
  cursor: default;
  box-shadow: 0rem 0.188rem 0.313rem var(--grey-400);
}

h2 {
  font-weight: 800;
  font-size: 1.25em;
  margin: 0;
  color: var(--grey-900);
}

h3 {
  color: var(--grey-700);
  font-size: 0.9em;
  font-weight: 600;
  margin: 0.25em 0 0 0;
}

.project-metadata-container > * {
  font-size: 0.75em;
  color: var(--grey-900);
}

.address-container {
  margin-top: 0.5em;
}

.address-container p {
  font-size: 0.9em;
  margin: 0;
  color: var(--grey-700);
  letter-spacing: 0;
  line-height: 1.2;
}

.project-metadata-container h4 {
  color: var(--grey-700);
  font-weight: 400;
  letter-spacing: 0;
  font-size: 1.125em
}

.project-metadata-container span {
  display: block;
  font-weight: 700;
  color: var(--grey-900);
  margin-top: 0.25em;
}

.project-metadata-container .badge {
  color: white;
}

a {
  text-decoration: none;
  color: var(--grey-900);
}

.btn-link:hover {
  color: #007bff;
  background-color: white;
}

.disabled a {
  pointer-events: none;
}

figure {
  margin: 0;
  padding: 0;
  padding-top: 56.25%;
  background: var(--grey-100);
  background-image: url('~@/assets/svg/placeholder-graphic.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 3.25rem 2.688rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

figure img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.missing-met-data-container {
  padding-top: 0rem;
}

.missing-met-data-text {
  font-size: 0.625rem;
}

.missing-met-data-button {
  font-size: 0.563rem;
  padding: 0.125rem;
}



.sep-line{
  margin: 0;
  padding: 0;
  border: 0;
}
</style>

<style>
.sort-by-button {
  font-size: 0.563rem !important;
  padding: 0.125rem !important;
}

.content-container {
  cursor: default;
}

.spinner-border {
  margin: 0 0.313rem 0 0 !important;
}


</style>